html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}
/* Sections
   ========================================================================== */
body {
  margin: 0;
  font-family: 'TissotSans', sans-serif;
}
html[lang="ru"] body {
  font-family: 'Roboto', sans-serif;
}
html[lang="zh"] body {
  font-family: 'TissotSans', 'Noto Sans SC', sans-serif;
}
html[lang="zht"] body {
  font-family: 'TissotSans', 'Noto Sans TC', sans-serif;
}
main {
  display: block;
}
/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
/* Grouping content
   ========================================================================== */
/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/* Text-level semantics
   ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent;
}
/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}
/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: 600er;
}
/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}
/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%;
}
/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
/* Embedded content
   ========================================================================== */
/**
 * Remove the border on images inside links in IE 10.
 */
img {
  border-style: none;
}
/* Forms
   ========================================================================== */
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */
}
/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}
/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}
/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}
/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  vertical-align: baseline;
}
/**
 * Remove the default vertical scrollbar in IE 10+.
 */
textarea {
  overflow: auto;
}
/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}
/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}
/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}
/* Interactive
   ========================================================================== */
/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */
details {
  display: block;
}
/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item;
}
/* Misc
   ========================================================================== */
/**
 * Add the correct display in IE 10+.
 */
template {
  display: none;
}
/**
 * Add the correct display in IE 10.
 */
[hidden] {
  display: none;
}
.base {
  padding-top: 62px;
}
.base > footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7em;
  z-index: 10;
}
.base > footer a {
  color: #223c68;
}
.base > footer a.sidelink {
  position: fixed;
  right: 0;
  bottom: 1.4em;
  padding: 0.5em 0.8em 1em;
  background-color: #575756;
  color: white;
  transform-origin: bottom right;
  transform: rotate(-90deg) translateX(100%);
  transition: all 0.3s ease;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.2);
  font-size: 1.2em;
}
.base > footer a.sidelink svg {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
}
.base > footer a.sidelink:hover {
  transform: rotate(-90deg) scale(1.2) translateX(98%);
  text-decoration: none;
  background-color: #171717;
}
.container {
  max-width: 1330px;
  margin: 0 auto;
}
html {
  height: 100%;
}
body {
  position: relative;
  box-sizing: border-box;
  color: #575756;
  padding-bottom: 3em;
  min-height: 100%;
}
h2 {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.loading-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background-color: #575756;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
.loading-page.img-1 {
  background-image: url(./assets/loading1.jpg);
}
.loading-page.img-2 {
  background-image: url(./assets/loading2.jpg);
}
.loading-page.img-3 {
  background-image: url(./assets/loading3.jpg);
}
.loading-page .spinner-container {
  position: absolute;
  bottom: 2em;
  right: 2em;
}
.loading-page .logo {
  position: absolute;
  top: 2em;
  left: 2em;
  width: 200px;
  height: 96px;
}
.mt-1 {
  margin-top: 0.5em;
}
.mt-2 {
  margin-top: 1em;
}
.mb-1 {
  margin-bottom: 0.5em;
}
.mb-2 {
  margin-bottom: 1em;
}
.mb-3 {
  margin-bottom: 2em;
}
a.simplelink {
  color: #575756;
}
.banner {
  position: fixed;
  background: #ff0000;
  color: white;
  top: 62px;
  left: 50%;
  z-index: 49;
  padding: 25px 20px 15px;
  border-radius: 10px;
  transform: translateY(-80px) translateX(-50%);
  animation: slidein 0.5s ease 1s forwards;
  transition: background-color 0.4s ease;
  text-align: center;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
.banner:hover {
  background-color: #ff3333;
}
.banner .content {
  font-weight: 700;
  font-size: 1.25em;
  text-align: center;
}
.banner .content button {
  width: 100%;
  box-sizing: border-box;
  display: block;
  color: white;
  text-decoration: none;
  font-size: 0.8em;
}
.banner .content button svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
}
.banner .content button svg * {
  fill: white;
}
@keyframes slidein {
  0% {
    transform: translateY(-50px) translateX(-50%);
  }
  100% {
    transform: translateY(-10px) translateX(-50%);
  }
}
@media screen and (max-width: 1360px) {
  .container {
    max-width: 1180px;
  }
}
@media screen and (max-width: 1210px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 640px) {
  .base > footer a.sidelink {
    position: static;
    display: inline-block;
    padding: 0.5em 0.8em;
    margin: 0.5em 0 0.2em 0.5em;
    background-color: #f4f4f4;
    color: #575756;
    transform: none;
    box-shadow: none;
  }
  .base > footer a.sidelink:hover {
    transform: none;
  }
  .base > footer a.sidelink svg path {
    fill: #575756;
  }
}
a,
button {
  display: inline;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  text-decoration: none;
  color: red;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  text-align: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
}
a:hover,
button:hover {
  text-decoration: underline;
}
.t-center {
  text-align: center;
}
.show-mobile {
  display: none;
}
@media screen and (max-width: 640px) {
  .show-mobile {
    display: block;
  }
  .hide-mobile {
    display: none;
  }
}
.btn {
  background-color: #575756;
  border: 0;
  text-transform: uppercase;
  color: white;
  font-weight: normal;
  letter-spacing: 0.05em;
  padding: 0.4em 1em;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s ease-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
}
.btn:hover {
  text-decoration: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  background-color: #000;
  /*background-color: #f0f0f0;*/
}
.grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.grid.middle {
  align-items: center;
}
.grid.bottom {
  align-items: flex-end;
}
.grid.stretch {
  align-items: stretch;
}
.grid > * {
  width: 23.5%;
}
.grid > .top {
  align-self: flex-start;
}
.grid > .bottom {
  align-self: flex-end;
}
.grid .col-2 {
  width: 49%;
}
.grid .col-3 {
  width: 74.5%;
}
.grid .col-1-3 {
  width: 32%;
}
.grid .col-2-3 {
  width: 66%;
}
.grid .grid > * {
  width: 48%;
}
.grid.subgrid-std > * {
  width: 23.5%;
}
.grid.subgrid-std .col-2 {
  width: 49%;
}
.grid.subgrid-std .col-3 {
  width: 74.5%;
}
.grid.wide > * {
  width: 20.5%;
}
.grid.wide .col-2 {
  width: 47%;
}
.grid.wide .col-3 {
  width: 73.5%;
}
@media screen and (max-width: 1210px) {
  .grid .m-col-1,
  .grid.subgrid-std .m-col-1 {
    width: 23.5%;
  }
  .grid .m-col-2,
  .grid.subgrid-std .m-col-2 {
    width: 49%;
  }
  .grid .m-col-3,
  .grid.subgrid-std .m-col-3 {
    width: 74.5%;
  }
}
@media screen and (max-width: 990px) {
  .grid > *,
  .grid.subgrid-std > *,
  .grid .col-2,
  .grid.subgrid-std .col-2 {
    width: 49%;
  }
  .grid .col-3,
  .grid.subgrid-std .col-3 {
    width: 100%;
  }
  .grid .s-col-1,
  .grid.subgrid-std .s-col-1 {
    width: 49%;
  }
  .grid .s-col-2,
  .grid.subgrid-std .s-col-2,
  .grid .col-1-3,
  .grid.subgrid-std .col-1-3,
  .grid .col-2-3,
  .grid.subgrid-std .col-2-3 {
    width: 100%;
  }
  .grid-small-reverse {
    display: flex;
    flex-flow: column-reverse;
  }
}
@media screen and (max-width: 640px) {
  .grid,
  .grid.subgrid-std {
    display: block;
  }
  .grid > *,
  .grid.subgrid-std > *,
  .grid .col-2,
  .grid.subgrid-std .col-2,
  .grid .col-3,
  .grid.subgrid-std .col-3,
  .grid .s-col-1,
  .grid.subgrid-std .s-col-1,
  .grid .s-col-2,
  .grid.subgrid-std .s-col-2,
  .grid .col-1-3,
  .grid.subgrid-std .col-1-3,
  .grid .col-2-3,
  .grid.subgrid-std .col-2-3 {
    width: auto;
  }
  .grid-mobile-reverse {
    display: flex;
    flex-flow: column-reverse;
  }
}
.pimp-my-select {
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 500;
  color: #575756;
  line-height: 1.3;
  padding: 0.3em 1.6em 0.2em 0.5em;
  max-width: 100%;
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.11 6.94'%3E%3Cpath d='M5.06,6.94.11.81A.5.5,0,0,1,.89.19L5.06,5.34,9.22.19A.51.51,0,0,1,9.93.11a.5.5,0,0,1,.07.7Z' transform='translate(0 0)' fill='%23575756'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%;
  background-size: 0.65em auto;
}
/* Hide arrow icon in IE browsers */
.pimp-my-select::-ms-expand {
  display: none;
}
/* Hover style */
.pimp-my-select:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.05);
}
/* Focus style */
.pimp-my-select:focus {
  border-color: #aaa;
  color: #222;
  outline: 1px dotted;
}
/* Set options to normal weight */
.pimp-my-select option {
  font-weight: normal;
}
.form .form-element {
  margin: 1em 0;
}
.form .form-element.error input[type="text"],
.form .form-element.error input[type="password"] {
  color: #ff0000;
  border-color: #ff0000;
}
.form label {
  display: block;
  margin-bottom: 0.25em;
}
.form input[type="text"],
.form input[type="password"] {
  background-color: #f4f4f4;
  font-size: inherit;
  padding: 0.3em 0.6em;
  border-radius: 4px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  color: #575756;
  border: 1px solid #f4f4f4;
}
.form button[type="submit"],
.form input[type="submit"] {
  background-color: #ff0000;
  color: white;
  text-align: center;
  font-size: inherit;
  padding: 0.5em 1em;
  border-radius: 4px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  border: 0;
  cursor: pointer;
}
.form button[type="submit"]:hover,
.form input[type="submit"]:hover {
  background-color: #990000;
}
.form .error-message {
  color: #ff0000;
  padding-top: 0.3em;
}
.about-tissot {
  color: #706f6f;
}
.about-tissot img {
  max-width: 100%;
}
.about-tissot h1 {
  font-size: 4em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.1;
}
.about-tissot h2 {
  margin: 0 0 0.2em;
  font-size: 5em;
  font-weight: bold;
  line-height: 1;
  color: #646363;
}
.about-tissot h2.big {
  font-size: 8em;
  margin: 0.25em 0 0.1em;
}
.about-tissot h3 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0 0 0.5em;
}
.about-tissot h2.colored strong,
.about-tissot h3.colored strong {
  color: #ff0000;
  font-weight: inherit;
}
.about-tissot .chapo {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.about-tissot .text-base {
  hyphens: auto;
  text-align: justify;
}
.about-tissot .text-base > :first-child {
  margin-top: 0;
}
.about-tissot .margin-2 {
  margin-top: 2em;
}
.about-tissot .head {
  position: relative;
}
.about-tissot .head .chapo {
  margin-bottom: 3em;
}
.about-tissot .head img {
  display: block;
  width: 100%;
}
.about-tissot .head .bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 55%;
  background: url(./assets/about-head.jpg) no-repeat 0% 50%;
  background-size: cover;
}
.about-tissot .head.negative {
  background-color: black;
  color: white;
}
.about-tissot .head.negative h2,
.about-tissot .head.negative h3 {
  color: white;
}
.about-tissot .head.negative h1 {
  color: #ff0000;
}
.about-tissot .page-section {
  overflow: hidden;
}
.about-tissot .page-section.negative {
  background-color: black;
  color: white;
}
.about-tissot .page-section.negative h2,
.about-tissot .page-section.negative h3 {
  color: white;
}
.about-tissot .page-section.padding {
  padding-top: 2em;
  padding-bottom: 2em;
}
.about-tissot .legend {
  margin: 1em 0 0;
  font-size: 0.8em;
}
.about-tissot .legend.left {
  padding-left: 1em;
}
.about-tissot .legend.right {
  text-align: right;
  padding-right: 1em;
}
.about-tissot .innovators {
  padding-top: 4em;
  margin-bottom: 4em;
}
.about-tissot .innovators h3 {
  color: #ff0000 !important;
  margin-bottom: 0.5em;
}
.about-tissot .innovators .content {
  position: relative;
}
.about-tissot .innovators .tradition {
  margin: 6em 0 0 -25%;
}
.about-tissot .swissness {
  position: relative;
  padding-bottom: 3em;
}
.about-tissot .swissness > .img-circle {
  position: absolute;
  right: calc(50% + 450px);
  left: 0;
  top: 0;
}
.about-tissot .swissness > .img-circle img {
  width: 100%;
}
.about-tissot .swissness .chapo {
  margin: 0.7em 0;
}
.about-tissot .swissness .col-text {
  position: relative;
  min-height: 30.5em;
}
.about-tissot .swissness .col-text .swiss {
  position: absolute;
  bottom: 0;
  left: 109%;
  width: 50px;
}
.about-tissot .swissness .grid-3 .col-2 {
  padding-top: 2em;
}
.about-tissot .swissness .swiss-2 {
  display: none;
}
.about-tissot .history {
  padding: 3em 0;
  position: relative;
}
.about-tissot .history h2 {
  text-align: right;
  margin: 0.4em 0;
}
.about-tissot .history h3 {
  text-align: right;
}
.about-tissot .history .video {
  position: relative;
  padding-top: 56.25%;
}
.about-tissot .history .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.about-tissot .history a {
  margin-top: 1.5em;
  position: relative;
  display: inline-block;
  color: white;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 3em 11px 15px;
  background: #575756;
}
.about-tissot .history a:hover {
  text-decoration: none;
}
.about-tissot .history a:after {
  content: '';
  position: absolute;
  right: -0.7em;
  top: 50%;
  background-color: white;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 20'%3E%3Ctitle%3Eplay%3C/title%3E%3Cpath d='M10,10L0,0L0,20Z' fill='%23575756'/%3E%3C/svg%3E");
  background-position: 50% 50%;
  background-size: 10px 20px;
  background-repeat: no-repeat;
  width: 2.3em;
  height: 2.3em;
  border-radius: 100px;
  border: 8px solid #575756;
  transform: translateY(-50%);
}
.about-tissot .two-parts {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.about-tissot .two-parts .content {
  width: 50%;
  box-sizing: border-box;
  padding-right: 14px;
  text-align: right;
}
.about-tissot .two-parts .content .content-inside {
  text-align: left;
  max-width: 652px;
  display: inline-block;
}
.about-tissot .two-parts .img {
  width: 50%;
}
.about-tissot .two-parts .img img {
  width: 100%;
}
.about-tissot .two-parts.top {
  align-items: flex-start;
}
.about-tissot .two-parts.right .content {
  padding-right: 0;
  padding-left: 14px;
  text-align: left;
}
.about-tissot .advertising .content img {
  margin-top: 2em;
  width: 48%;
}
.about-tissot .advertising .img-circle {
  width: 50%;
  text-align: right;
}
.about-tissot .advertising .img-circle img {
  width: 95%;
}
.about-tissot .sports {
  padding: 3em 0;
}
.about-tissot .sports .head {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: 3em;
}
.about-tissot .sports .head .img {
  flex-grow: 1;
}
.about-tissot .sports .head .content {
  width: 652px;
  margin: 0 26px;
}
.about-tissot .sports .head .img-1 {
  background: url(./assets/sports1.jpg) no-repeat 50% 50%;
  background-size: cover;
}
.about-tissot .sports .head .img-2 {
  background: url(./assets/sports2.jpg) no-repeat 100% 50%;
  background-size: cover;
}
.about-tissot .sports .logos-titles {
  text-transform: uppercase;
  padding: 0.2em 0;
  color: white;
  background-color: #b2b2b2;
  display: flex;
  flex-flow: row nowrap;
  font-size: 1.25em;
  justify-content: space-around;
  margin-bottom: 1.5em;
}
.about-tissot .sports .logos {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 1.75em;
}
.about-tissot .sports .logos.logos-split {
  justify-content: space-between;
}
.about-tissot .sports .logos.logos-split .content {
  width: 48%;
}
.about-tissot .ambassadors {
  position: relative;
}
.about-tissot .ambassadors .img-num {
  position: relative;
}
.about-tissot .ambassadors .img-num::after {
  position: absolute;
  bottom: 0.8em;
  left: 0.6em;
  background-color: rgba(0, 0, 0, 0.5);
  content: attr(data-num);
  color: white;
  width: 1em;
  height: 1em;
  padding: 0.2em;
  font-size: 0.75em;
  text-align: center;
  font-weight: 600;
  border-radius: 200px;
}
.about-tissot .ambassadors .img-num.pos-right::after {
  left: auto;
  right: 0.6em;
}
.about-tissot .ambassadors .img-num.pos-top::after {
  bottom: auto;
  top: 1em;
}
.about-tissot .ambassadors .img-1 {
  position: absolute;
  left: 0;
  right: calc(50% + 312.5px + 40px);
  top: 0;
}
.about-tissot .ambassadors .img-1 img {
  width: 100%;
}
.about-tissot .ambassadors h3,
.about-tissot .ambassadors h4 {
  font-size: 1.25em;
  font-weight: 700;
}
.about-tissot .ambassadors h3 {
  margin: 1em 0 0 0;
}
.about-tissot .ambassadors h3.no-margin {
  margin-top: 0;
}
.about-tissot .ambassadors h3.colored {
  font-size: 3em;
  line-height: 1;
}
.about-tissot .ambassadors h3.colored strong {
  display: block;
}
.about-tissot .ambassadors h4 {
  text-transform: uppercase;
  margin: 0 0 1em;
}
.about-tissot .training .head {
  background: url(./assets/training.jpg) no-repeat 0% 50%;
  background-size: auto 100%;
}
.about-tissot .training .head h2 {
  padding-top: 1em;
}
.about-tissot .training .head h3 {
  font-weight: 400;
  margin: 0;
  padding-bottom: 2.5em;
}
.about-tissot .training h4 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.5em;
}
.about-tissot .training .events {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-top: 2em;
  margin-bottom: 3em;
}
.about-tissot .training .events .img {
  flex-grow: 1;
}
.about-tissot .training .events .content {
  max-width: 800px;
  margin: 0 28px;
  box-sizing: border-box;
  padding: 2em 6em;
  background: white;
}
.about-tissot .training .events .img-1 {
  flex-grow: 2;
  background: url(./assets/training3.jpg) no-repeat 50% 50%;
  background-size: cover;
}
.about-tissot .training .events .img-2 {
  flex-grow: 1;
  background: url(./assets/training4.jpg) no-repeat 50% 50%;
  background-size: cover;
}
.about-tissot .tips h2 {
  padding-top: 1em;
}
.about-tissot .tips h4 {
  font-size: 1.5em;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.about-tissot .tips h4::first-letter {
  font-size: 2.2em;
  line-height: 0;
}
.about-tissot .show-small {
  display: none;
}
@media screen and (max-width: 1360px) {
  .about-tissot .innovators .img-container {
    left: calc(50% + 277px + 35px);
  }
  .about-tissot .swissness > .img-circle {
    right: calc(50% + 380px);
  }
  .about-tissot .two-parts .content {
    padding-right: 12px;
  }
  .about-tissot .two-parts .content .content-inside {
    max-width: 578px;
  }
  .about-tissot .two-parts.right .content {
    padding-left: 12px;
  }
  .about-tissot .sports .head .content {
    width: 578px;
    margin: 0 24px;
  }
  .about-tissot .ambassadors {
    position: relative;
  }
  .about-tissot .ambassadors .img-1 {
    right: calc(50% + 277px + 35px);
  }
}
@media screen and (max-width: 1210px) {
  .about-tissot .innovators .tradition {
    margin: 1em 0 0 0;
  }
  .about-tissot .swissness > .img-circle {
    right: 76%;
  }
  .about-tissot .two-parts .content {
    padding-left: 1%;
    padding-right: 15px;
  }
  .about-tissot .two-parts .content .content-inside {
    max-width: 578px;
  }
  .about-tissot .two-parts.right .content {
    padding-left: 1%;
    padding-right: 15px;
  }
  .about-tissot .sports .head .content {
    width: 49%;
    margin: 0 2%;
  }
  .about-tissot .ambassadors .img-1 {
    right: 76%;
  }
}
@media screen and (max-width: 990px) {
  .about-tissot h2 {
    font-size: 2.5em;
  }
  .about-tissot h2.big {
    font-size: 4em;
  }
  .about-tissot .show-small {
    display: block;
  }
  .about-tissot .hide-small,
  .about-tissot .img-circle {
    display: none;
  }
  .about-tissot .head {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .about-tissot .head .bg {
    display: none;
  }
  .about-tissot .head .grid {
    display: block;
  }
  .about-tissot .head .grid .col-2 {
    width: auto;
  }
  .about-tissot .head .container {
    max-width: 578px;
  }
  .about-tissot .two-parts {
    display: block;
    padding: 0 15px;
  }
  .about-tissot .two-parts .content {
    width: auto;
    padding-left: 0;
    padding-right: 0;
  }
  .about-tissot .two-parts .content .content-inside {
    display: block;
    margin: 0 auto;
  }
  .about-tissot .two-parts.right .content {
    padding-left: 0;
    padding-right: 0;
  }
  .about-tissot .page-section {
    padding: 5em 0 1em;
    position: relative;
  }
  .about-tissot .page-section:before {
    content: '';
    background: #bbb;
    height: 1px;
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
  }
  .about-tissot .innovators .img-container {
    position: static;
    width: auto;
    max-width: 578px;
    margin: 0 auto;
  }
  .about-tissot .innovators .container {
    max-width: 578px;
  }
  .about-tissot .innovators .grid {
    display: block;
  }
  .about-tissot .innovators .grid > *,
  .about-tissot .innovators .grid .col-2,
  .about-tissot .innovators .grid .col-text {
    max-width: 578px;
    width: auto;
    margin: 1em auto;
  }
  .about-tissot .swissness .col-text {
    min-height: 0;
  }
  .about-tissot .swissness .grid {
    display: block;
  }
  .about-tissot .swissness .grid > *,
  .about-tissot .swissness .grid .col-2,
  .about-tissot .swissness .grid .col-text {
    max-width: 578px;
    width: auto;
    margin: 1em auto;
  }
  .about-tissot .advertising .content img {
    width: auto;
  }
  .about-tissot .history .container {
    max-width: 578px;
  }
  .about-tissot .history .grid {
    display: block;
  }
  .about-tissot .history .grid .col-2 {
    width: auto;
  }
  .about-tissot .sports .head {
    display: block;
    max-width: 578px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .about-tissot .sports .head .img-1 {
    padding-top: 35%;
    margin-bottom: 1em;
  }
  .about-tissot .sports .head .img-2 {
    display: none;
  }
  .about-tissot .sports .head .content {
    width: auto;
    margin: 0;
  }
  .about-tissot .sports .grid {
    display: block;
  }
  .about-tissot .sports .grid .col-2 {
    width: auto;
  }
  .about-tissot .ambassadors h3 {
    margin-top: 0.25em;
  }
  .about-tissot .ambassadors .img-1 {
    position: static;
    max-width: 260px;
  }
  .about-tissot .ambassadors .grid {
    display: flex;
    flex-flow: column;
  }
  .about-tissot .ambassadors .grid > *,
  .about-tissot .ambassadors .grid .col-2 {
    width: auto;
  }
  .about-tissot .ambassadors .grid > * img,
  .about-tissot .ambassadors .grid .col-2 img {
    margin: 1em 0;
  }
  .about-tissot .ambassadors .two-parts .img {
    width: auto;
    max-width: 578px;
    margin: 0 auto;
  }
  .about-tissot .ambassadors .container {
    max-width: 578px;
  }
  .about-tissot .ambassadors .img-first {
    max-width: 300px;
  }
  .about-tissot .ambassadors > .two-parts:last-child {
    display: flex;
    flex-flow: column-reverse;
  }
  .about-tissot .ambassadors .img-num::after {
    display: none;
  }
  .about-tissot .ambassadors .order-1 {
    order: 1;
  }
  .about-tissot .ambassadors .order-2 {
    order: 2;
  }
  .about-tissot .ambassadors .order-3 {
    order: 3;
  }
  .about-tissot .ambassadors .order-4 {
    order: 4;
  }
  .about-tissot .training .container {
    max-width: 578px;
  }
  .about-tissot .training .head {
    background: none;
  }
  .about-tissot .training .grid {
    display: block;
  }
  .about-tissot .training .grid .col-2 {
    width: auto;
  }
  .about-tissot .training .grid .col-2:first-child {
    padding-top: 35%;
    background: url(./assets/training.jpg) no-repeat 0% 50%;
    background-size: auto 100%;
  }
  .about-tissot .training .events {
    display: block;
    padding: 15px;
  }
  .about-tissot .training .events .content {
    padding: 0;
    max-width: 578px;
    margin: 0 auto;
  }
  .about-tissot .training .events .img {
    display: none;
  }
  .about-tissot .tips .grid .col-2:first-child {
    width: 23.5%;
  }
  .about-tissot .tips .grid .col-2:last-child {
    width: 74.5%;
  }
}
@media screen and (max-width: 800px) {
  .about-tissot .swissness .col-text .swiss {
    display: none;
  }
  .about-tissot .swissness .swiss-2 {
    display: block;
    float: left;
    width: 4em;
    margin: 0 1em 10px 0;
  }
}
@media screen and (max-width: 640px) {
  .about-tissot .sports .logos {
    flex-flow: row wrap;
  }
  .about-tissot .sports .logos img {
    margin: 0 1em 10px;
  }
  .about-tissot .tips h4 {
    font-size: 1.3em;
  }
  .about-tissot .tips .grid .col-2:first-child {
    width: auto;
    max-width: 240px;
    margin: 2em auto 0;
  }
  .about-tissot .tips .grid .col-2:last-child {
    width: auto;
  }
}
@media screen and (max-width: 420px) {
  .about-tissot {
    line-height: 1.4;
  }
  .about-tissot h2,
  .about-tissot h2.big {
    font-size: 2.5em;
    line-height: 1.1;
  }
  .about-tissot h3 {
    font-size: 1.25em;
    line-height: 1.1;
  }
}
.add-home {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  background: #575756;
  box-shadow: 0px -3px 5px rgba(0, 0, 0, 0.1);
}
.add-home .btn-show {
  display: block;
  box-sizing: border-box;
  padding: 15px 15px;
  width: 100%;
  text-transform: uppercase;
  color: white;
}
.add-home .btn-show:hover {
  text-decoration: none;
}
.add-home .btn-close {
  position: absolute;
  top: 50%;
  right: 0.5em;
  transform: translateY(-50%);
  width: 1.5em;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 2em;
  border: 1px solid white;
  border-radius: 200px;
}
.add-home .btn-close:after,
.add-home .btn-close:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0.3em;
  right: 0.3em;
  height: 1px;
  background: white;
}
.add-home .btn-close:after {
  transform: translateY(-50%) rotate(45deg);
}
.add-home .btn-close:before {
  transform: translateY(-50%) rotate(-45deg);
}
.dropdown {
  position: relative;
}
.dropdown > button {
  display: block;
  padding: 0.3em 1em 0.2em 1.6em;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.25em;
  position: relative;
  letter-spacing: 0.05em;
  background: rgba(86, 86, 86, 0.95);
  box-sizing: border-box;
  width: 100%;
}
.dropdown > button:hover {
  text-decoration: none;
  background-color: #575756;
}
.dropdown > button:after {
  content: '';
  width: 0.4em;
  height: 0.4em;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  margin-left: 1em;
  transform: translateY(-60%) rotate(45deg);
  transition: transform 0.3s ease;
  display: inline-block;
}
.dropdown .dropdown-content {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1em 2em;
  background: rgba(86, 86, 86, 0.95);
  z-index: 10;
}
.dropdown.opened .dropdown-content {
  display: block;
}
.dropdown.opened > button:after {
  transform: translateY(-20%) rotate(225deg);
}
.dropdown .btn-filter {
  display: block;
  padding: 0.3em 2em 0.2em 0;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  position: relative;
  letter-spacing: 0.05em;
  box-sizing: border-box;
  width: 100%;
}
.dropdown .btn-filter:hover {
  text-decoration: none;
}
.dropdown .btn-filter:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 0.5em;
  height: 0.5em;
  border-radius: 100px;
  border: 1px solid white;
  transform: translateY(-50%);
}
.dropdown .btn-filter.active:after {
  background: white;
}
.sort-list .btn-filter {
  font-weight: 700;
}
.sort-list .btn-filter:before {
  content: '';
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 2em;
}
.filters-list {
  margin-bottom: 2em;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.filters-list .col {
  width: 31%;
}
.filters-list .filter-filter h3 {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  position: relative;
  margin: 1em 0 0.35em;
  padding-top: 0.6em;
}
.filters-list .filter-filter h3:before {
  content: '';
  background: white;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  width: 2em;
}
.dropdown-filters .btn {
  background: transparent;
  border: 1px solid #ccc;
  color: white;
}
.dropdown-filters.large .dropdown-content {
  right: -34.2%;
}
.dropdown-filters.large .dropdown-content .col {
  width: 22%;
}
.dropdown-filters.large .dropdown-content .col:nth-child(2) {
  padding-top: 1.7em;
}
.btn.btn-remove-filter {
  background-color: white;
  font-size: 0.875em;
  border-radius: 4px;
  border: 1px solid #bbb;
  color: #575756;
  padding: 0.25em 0.5em;
  margin: 0 0.4em 0 0;
  white-space: nowrap;
  letter-spacing: 0;
  text-transform: none;
}
.btn.btn-remove-filter .label {
  color: #8a8a89;
}
.btn.btn-remove-filter svg {
  width: 12px;
  height: 12px;
  border-radius: 100px;
  background: #575756;
  margin-left: 4px;
}
.btn.btn-remove-filter.btn-reset {
  background-color: #575756;
  color: white;
}
.btn.btn-remove-filter.btn-reset svg {
  background-color: white;
}
@media screen and (max-width: 990px) {
  .dropdown-filters.large .dropdown-content,
  .dropdown-filters .dropdown-content {
    right: -104%;
  }
  .dropdown-filters.large .dropdown-content {
    padding: 1em;
  }
  .dropdown-filters.large .btn-filter {
    font-size: 0.875em;
  }
}
@media screen and (max-width: 640px) {
  .filters-list {
    display: block;
  }
  .filters-list .col {
    width: auto;
  }
  .dropdown-filters {
    margin-bottom: 4px;
  }
  .dropdown-filters.large .dropdown-content,
  .dropdown-filters .dropdown-content {
    right: 0;
  }
  .dropdown-filters.large .dropdown-content .col,
  .dropdown-filters .dropdown-content .col {
    width: auto;
  }
  .dropdown-filters.large .dropdown-content .col:nth-child(2) {
    padding-top: 0em;
  }
  .dropdown-filters.large .btn-filter {
    font-size: 1em;
  }
}
.faq-answer {
  padding-bottom: 1.75em;
  padding-top: 1.25em;
  border-bottom: 1px solid #bbb;
}
.faq-answer .grid .col-1 {
  width: 23.5%;
}
.faq-answer .grid .col-3 {
  width: 74.5%;
}
.faq-answer img.left,
.faq-answer img.bottom {
  margin-top: 1.1em;
}
.faq.contents-page {
  padding-top: 2.75em;
}
.faq.contents-page .content-menu ul {
  white-space: normal;
}
.faq.contents-page .content-menu ul.main-menu {
  column-count: 2;
}
.faq.contents-page .content-menu ul.main-menu li {
  white-space: normal;
}
@media screen and (max-width: 640px) {
  .faq.contents-page .content-menu ul.main-menu {
    column-count: 1;
  }
}
@media screen and (max-width: 1210px) {
  .faq-answer .grid {
    display: block;
  }
  .faq-answer .grid .col-1 {
    width: auto;
  }
  .faq-answer .grid .col-3 {
    width: auto;
  }
  .faq-answer img.left {
    max-width: 300px;
  }
}
body.front {
  padding-bottom: 0;
}
body.front .base {
  box-sizing: border-box;
  height: 100vh;
  padding-bottom: 3em;
  position: relative;
}
body.front .base .main {
  box-sizing: border-box;
  padding-top: 2.3em;
}
body.front .base .main,
body.front .base .container,
body.front .base .homepage {
  height: 100%;
}
.homepage > * {
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.homepage > * > .grid {
  height: 31.5%;
}
.homepage img {
  max-width: 100%;
}
.homepage a {
  height: 100%;
  background-size: cover;
  background-position: 50% 100%;
  color: white;
  position: relative;
  display: block;
  text-align: center;
}
.homepage a:hover {
  text-decoration: none;
}
.homepage a .text {
  position: absolute;
  bottom: 1.5em;
  left: 1em;
  right: 1em;
}
.homepage .shadow .text {
  text-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
}
.homepage .big {
  height: 65.75%;
}
.homepage .big h2 {
  font-size: 3em;
  margin: 0;
}
.homepage .big span {
  margin: 0.1em 0 0.5em;
  font-size: 1.5em;
  display: block;
  text-transform: uppercase;
}
.homepage .big .text {
  bottom: 1.6em;
}
.homepage .big.half {
  height: 31.5%;
}
.homepage .small .text {
  left: 2em;
  right: 2em;
}
.homepage .small h2 {
  font-size: 1.5em;
}
.homepage svg {
  width: 26px;
  height: 26px;
}
.homepage-mobile {
  display: none;
  padding: 1em 2em;
}
.homepage-mobile a {
  color: white;
  text-align: center;
  display: block;
  padding: 1.25em 0;
  border-bottom: 2px solid white;
}
.homepage-mobile a h2 {
  margin: 0;
  padding: 0 15px;
  font-size: 1.6em;
  font-weight: 700;
  text-shadow: 5px 5px 3px rgba(0, 0, 0, 0.4);
}
@media screen and (max-width: 1360px) {
  .homepage .small .text {
    bottom: 1em;
    left: 1em;
    right: 1em;
  }
  .homepage .small h2 {
    font-size: 1.3em;
  }
}
@media screen and (max-width: 1210px) {
  .homepage .small h2 {
    font-size: 1.15em;
  }
  .homepage .big h2 {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 990px) {
  .homepage .big h2 {
    font-size: 2em;
  }
  .homepage .big span {
    font-size: 1em;
  }
  .homepage .small h2 {
    font-size: 1.2em;
    margin: 0;
  }
}
@media screen and (max-width: 800px) {
  .homepage .big h2 {
    font-size: 1.5em;
  }
  .homepage .small h2 {
    font-size: 1em;
    margin: 0;
  }
}
@media screen and (max-width: 640px), screen and (max-height: 620px) {
  body.front .base .main {
    padding-top: 0;
  }
  body.front .base .main .container {
    padding: 0;
    max-width: 640px;
    margin: 0 auto;
  }
  .banner {
    position: relative;
    animation: none;
    transform: translateX(-50%) translateY(0);
    border-radius: 0;
    padding-top: 15px;
    top: 0;
  }
  .homepage {
    display: none;
  }
  .homepage-mobile {
    display: block;
  }
  body.front {
    background-image: url(./assets/mobile-back.jpg);
    background-color: rgba(86, 86, 86, 0.95);
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: fixed;
  }
  body.front .base {
    height: auto;
    padding-bottom: 0;
  }
  body.front footer {
    position: static;
    background: transparent;
    color: white;
  }
}
@media screen and (max-width: 420px) {
  .homepage .big .text {
    bottom: 1.5em;
  }
  .homepage .small .text {
    left: 0.5em;
    right: 0.5em;
    bottom: 1em;
  }
  .homepage .small h2 {
    font-size: 0.8em;
  }
}
.links-page:before {
  content: '';
  position: fixed;
  z-index: 0;
  left: 0;
  top: 0;
  right: 50%;
  bottom: 0;
}
.links-page h1 {
  font-weight: 700;
  font-size: 4em;
  text-transform: uppercase;
  margin: 2em 0 0;
}
.links-page h2 {
  font-size: 1em;
  font-weight: 400;
  text-transform: uppercase;
}
.links-page .links {
  margin-top: 2em;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.links-page .links a {
  text-decoration: none;
  color: inherit;
}
.links-page .links a:hover {
  color: #ff0000;
}
.links-page .links > * {
  width: 25%;
  text-align: center;
  margin: 3em 1em 2em;
  font-weight: 400;
  text-transform: uppercase;
}
.links-page .links .search-form-straps {
  width: auto;
  flex-grow: 1;
  text-align: left;
}
.links-page .links img {
  display: block;
  margin-bottom: 1em;
  max-width: 100%;
}
.links-page .links.nowrap {
  flex-wrap: nowrap;
}
.merchandising:before {
  background: url(./assets/merchandising.jpg) no-repeat 50% 50%;
  background-size: cover;
  right: 55%;
}
.merchandising.links-page:after {
  content: '';
  position: absolute;
  background-color: rgba(255, 0, 0, 0.5);
  top: 0;
  right: 55%;
  width: 30px;
  bottom: 0;
}
.technical {
  position: relative;
}
.technical:before {
  position: absolute;
  background: url(./assets/technic.jpg) no-repeat 100% 50%;
  background-size: cover;
}
.about-page {
  position: relative;
}
.about-page:before {
  position: fixed;
  background: url(./assets/about-head.jpg) no-repeat 0% 50%;
  background-size: cover;
  right: 78%;
}
.about-page .video {
  position: relative;
  padding-top: 56.25%;
}
.about-page .video video,
.about-page .video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.customer-services .customer-services:before {
  background: url(./assets/strapspage.jpg) no-repeat 100% 50%;
  background-size: cover;
}
.customer-services .links {
  justify-content: left;
}
.links-page.straps:before {
  background: url(./assets/strapspage.jpg) no-repeat 100% 50%;
  background-size: cover;
}
.links-page.straps .subgrid-std {
  margin-top: 2em;
}
.links-page.straps .subgrid-std .col-3 {
  padding-left: 1em;
  box-sizing: border-box;
}
.links-page.straps .grid > :first-child {
  margin-bottom: 2em;
}
.links-page.straps .grid > :first-child h3 {
  margin-bottom: 1.5em;
}
.links-page.straps .grid h3 {
  font-size: 1.25em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0 0 0.5em;
}
.links-page.straps .grid img {
  width: 100%;
  height: auto;
  max-width: 120px;
  vertical-align: middle;
  margin-right: 1em;
}
.links-page.straps .grid a.btn {
  display: inline-block;
  text-align: center;
  margin: 0.75em 0;
}
.links-page.straps .imagelink a {
  display: block;
  text-decoration: none;
  color: inherit;
  text-transform: uppercase;
  text-align: center;
  font-weight: 400;
}
.links-page.straps .imagelink a:hover {
  color: #ff0000;
}
.links-page.straps .imagelink a img {
  margin: 0 0 0.7em;
}
@media screen and (max-width: 1210px) {
  .links-page:before {
    right: 75%;
  }
  .links-page.merchandising:before,
  .links-page.merchandising:after {
    right: 78%;
  }
}
@media screen and (max-width: 990px) {
  .merchandising {
    position: relative;
  }
  .links-page:before {
    position: static;
    display: block;
    width: auto;
    padding-top: 25%;
  }
  .links-page.merchandising:after {
    padding-top: 25%;
    bottom: auto;
    right: 0;
  }
  .links-page .grid {
    display: block;
  }
  .links-page .grid .col-2 {
    width: auto;
  }
  .links-page h1 {
    font-size: 2em;
    margin-top: 0.5em;
  }
  .links-page .links {
    justify-content: space-between;
  }
  .links-page .links > * {
    width: 45%;
    margin: 1em 0;
  }
  .links-page.straps .subgrid-std .col-3 {
    padding-left: 0;
  }
}
@media screen and (max-width: 640px) {
  .links-page .links.nowrap {
    display: block;
  }
  .links-page .links.nowrap > * {
    width: auto;
    margin: 1em 0;
  }
}
.message-container.full {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  background: rgba(255, 255, 255, 0.7);
}
.message-container.full:before {
  content: '';
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(./assets/loading2.jpg) no-repeat 50% 50%;
  background-size: cover;
  opacity: 0.3;
}
.message-container.full .message {
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1), 2px 2px 3px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 2em;
  width: 24em;
  box-sizing: border-box;
  max-width: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  z-index: 2;
}
.message-container.full.size-big .message {
  display: block;
  position: relative;
  top: 0;
  left: 0;
  transform: none;
  margin: 4em auto 2em;
  width: 48em;
}
.message-container.inline {
  margin: 0.5em 0;
  padding: 0.5em;
  border: 1px solid #bbb;
}
.message-container.inline:before {
  content: '!';
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  border: 1px solid #bbb;
  border-radius: 100px;
  margin-right: 0.3em;
  text-align: center;
  float: left;
}
.message-container.inline .message {
  margin-left: 2em;
}
.message-error {
  border: 1px solid #ffcccc;
  border-radius: 2px;
  padding: 0.3em 0.7em;
  margin: 0.75em 0;
  text-transform: none;
  color: #990000;
  background-color: #ffffff;
}
.message p {
  font-size: 1.25em;
}
.message p.sorry {
  font-size: 2em;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0.3em 0;
}
.modal-container {
  z-index: 250;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  background-color: rgba(255, 255, 255, 0.7);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-container .modal {
  padding: 4em 1.5em 1.5em;
  max-width: 800px;
  background: white;
  border-radius: 3px;
  box-shadow: 2px 8px 12px rgba(0, 0, 0, 0.2);
  margin: 0 auto;
  position: relative;
}
.modal-container .modal button {
  position: absolute;
  top: 15px;
  right: 15px;
}
.modal-container .modal button svg {
  width: 30px;
  height: 30px;
}
.modal-container video {
  max-width: 100%;
}
.navbar {
  background: #e9e9e8;
  padding: 15px;
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}
.navbar ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
}
.navbar ul li {
  display: inline-block;
  padding: 0;
  margin: 0;
}
.navbar ul li a {
  display: block;
  padding: 5px 10px;
}
.navbar .container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.navbar .menu-icons {
  white-space: nowrap;
}
.navbar .logo {
  width: 62px;
  height: 30px;
  margin-left: 1em;
  vertical-align: middle;
}
.navbar .selects {
  white-space: nowrap;
}
.navbar .selects > * {
  display: inline-block;
  white-space: normal;
}
.navbar .selects .store-switcher {
  max-width: 12em;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.navbar .switcher-container svg {
  vertical-align: middle;
  width: 18px;
  height: 18px;
}
.navbar .titles {
  display: inline-block;
  vertical-align: middle;
}
.navbar h1 {
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0.5em 0 0.6em;
  font-size: 1.2em;
  vertical-align: middle;
}
.navbar h2 {
  font-size: 1em;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 1em;
  font-weight: normal;
}
.navbar .subtitle {
  display: none;
}
.menu-container {
  white-space: normal;
  display: inline-block;
}
.menu-container > button {
  display: block;
  height: 32px;
  width: 32px;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 32px;
  position: relative;
}
.menu-container > button:before,
.menu-container > button:after,
.menu-container > button .bar {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  height: 6px;
  border-radius: 100px;
  background-color: #575756;
}
.menu-container > button:before {
  top: 50%;
  transform: translateY(-220%);
  transition: transform 0.3s ease;
}
.menu-container > button:after {
  top: 50%;
  transform: translateY(120%);
  transition: transform 0.3s ease;
}
.menu-container > button .bar {
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.2s ease;
}
.menu-container > button.opened:before {
  transform: translateY(-50%) rotate(45deg);
}
.menu-container > button.opened:after {
  transform: translateY(-50%) rotate(-45deg);
}
.menu-container > button.opened .bar {
  opacity: 0;
}
.menu-container.root a,
.menu-container.root button {
  font-weight: 700;
}
.menu-menu {
  position: fixed;
  right: 50%;
  top: 62px;
  z-index: 100;
  bottom: 0;
  transition: transform 0.3s ease;
  width: 665px;
  background: rgba(86, 86, 86, 0.95);
  color: white;
  padding: 0;
  text-align: left;
  transform: translateX(-200%);
}
.menu-menu:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  bottom: 0;
  width: 100%;
  background: rgba(86, 86, 86, 0.95);
}
.menu-menu.opened {
  transform: translateX(0%);
}
.menu-menu .menu-menu-inside {
  padding: 3em 0;
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.menu-menu a,
.menu-menu button {
  display: block;
  padding: 0.3em 0 0.1em;
  color: white;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5em;
  position: relative;
  letter-spacing: 0.05em;
}
.menu-menu a:hover,
.menu-menu button:hover {
  text-decoration: none;
  color: #ff0000;
}
.menu-menu button:after {
  content: '';
  width: 0.4em;
  height: 0.4em;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  margin-left: 1em;
  transform: translateY(-60%) rotate(45deg);
  display: inline-block;
}
.menu-menu button.video-btn {
  font-size: 0.875em;
  font-weight: normal;
}
.menu-menu button.video-btn svg {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin: 0 5px 2px 0;
}
.menu-menu button.video-btn svg path {
  fill: white;
}
.menu-menu button.video-btn:before,
.menu-menu button.video-btn::after {
  display: none;
}
.menu-menu .menu-menu-inside .btn-price {
  position: absolute;
  bottom: 1em;
  font-weight: normal;
  font-size: 0.875em;
  right: 2em;
}
.menu-menu .menu-menu-inside .btn-price svg {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-right: 0.5em;
}
.menu-menu .menu-menu-inside .btn-price:after,
.menu-menu .menu-menu-inside .btn-price:before {
  display: none;
}
.menu-menu .nav-collection,
.menu-menu .nav-static {
  margin-bottom: 1.8em;
}
.menu-menu .nav-collection--item.parent a,
.menu-menu .nav-collection--item.parent button {
  font-weight: 700;
}
.menu-menu .nav-collection--item.parent button:after {
  transform: translateY(0%) rotate(-135deg);
}
.menu-menu .nav-collection--item.see-all a,
.menu-menu .nav-collection--item.see-all button {
  padding-bottom: 0.3em;
  margin-bottom: 0.3em;
}
.menu-menu .nav-collection--item.see-all a:before,
.menu-menu .nav-collection--item.see-all button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 20px;
  height: 1px;
  background: white;
}
.menu-container.opened .menu-menu {
  right: 0;
}
.dropdown-search {
  vertical-align: middle;
}
.dropdown-search svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
}
.dropdown-search > button {
  padding: 0 5px;
  background: transparent;
}
.dropdown-search > button:after {
  display: none;
}
.dropdown-search > button:hover {
  background: transparent;
}
.dropdown-search .dropdown-content {
  position: fixed;
  left: 0;
  right: 0;
  top: 62px;
  background: transparent;
  padding: 0;
}
.breadcrumb {
  padding-top: 0.75em;
}
.breadcrumb a {
  color: #575756;
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  font-size: 0.875em;
}
.breadcrumb a + a {
  margin-left: 3em;
}
.breadcrumb a + a:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: -2em;
  width: 0.5em;
  height: 0.5em;
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
  transform: translateY(-50%) rotate(45deg);
}
.breadcrumb .see-more {
  display: none;
}
@media screen and (max-width: 1360px) {
  .menu-menu {
    padding-left: 20px;
    padding-right: 5em;
    width: auto;
    right: auto;
    left: 0;
  }
  .menu-menu:before {
    display: none;
  }
}
@media screen and (max-width: 1210px) {
  .navbar h2 {
    font-size: 0.875em;
    max-width: 16em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 900px) {
  .base {
    padding-top: 68px;
  }
  .navbar h1,
  .navbar h2 {
    display: block;
  }
  .navbar h2 {
    max-width: none;
  }
}
@media screen and (max-width: 700px) {
  .navbar .titles h2 {
    display: none;
  }
  .navbar .subtitle {
    display: block;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #d0d0ce;
  }
  .navbar .subtitle h2 {
    margin-left: 0;
    padding: 3px 5px;
  }
  .base {
    padding-top: 84px;
  }
  .contents-page .content-menu.fixed .content-menu-container {
    margin-top: 22px;
  }
}
@media screen and (max-width: 990px) {
  .navbar .selects .store-switcher {
    width: 8em;
    text-overflow: ellipsis;
  }
}
@media screen and (max-width: 640px) {
  .navbar .selects .store-switcher {
    display: none;
  }
  .breadcrumb {
    position: relative;
    padding-right: 2em;
    padding-top: 0;
    display: block;
    box-sizing: border-box;
    border-bottom: 1px solid #bbb;
    width: 100%;
    margin-bottom: 5px;
  }
  .breadcrumb .see-more {
    transform: rotate(90deg);
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    transition: all 0.3s;
  }
  .breadcrumb .breadcrumb-links {
    display: flex;
    flex-flow: column-reverse;
  }
  .breadcrumb a {
    padding: 8px 0;
    display: none;
  }
  .breadcrumb a:last-child {
    display: block;
  }
  .breadcrumb a + a {
    margin-left: 0;
  }
  .breadcrumb a + a:before {
    display: none;
  }
  .breadcrumb.opened a {
    display: block;
  }
  .breadcrumb.opened .see-more {
    transform: rotate(-90deg);
  }
}
@media screen and (max-width: 500px) {
  .navbar h1 {
    font-size: 0.75em;
  }
  .navbar .logo {
    width: 41px;
    height: 20px;
  }
}
@media screen and (max-width: 420px) {
  .navbar h1 {
    display: none;
  }
  .navbar .logo {
    width: 62px;
    height: 30px;
  }
}
.search-form {
  padding-top: 0.5em;
  background: rgba(86, 86, 86, 0.95);
  position: relative;
}
.search-form label {
  color: #ccc;
  position: absolute;
  top: 50%;
  left: 0.5em;
  transform: translateY(-50%);
  transition: 0.2s ease;
  cursor: pointer;
}
.search-form input {
  border: 0;
  background: transparent;
  color: white;
  padding: 0.5em;
  width: 100%;
}
.search-form input:focus {
  outline: none;
  box-shadow: 0;
}
.search-form.focus label {
  top: 20%;
  font-size: 0.8em;
}
.search-form .btn-clear {
  position: absolute;
  top: 50%;
  right: 0.5em;
  transform: translateY(-50%);
  width: 1.5em;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 2em;
  border: 1px solid white;
  border-radius: 200px;
}
.search-form .btn-clear:after,
.search-form .btn-clear:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0.3em;
  right: 0.3em;
  height: 1px;
  background: white;
}
.search-form .btn-clear:after {
  transform: translateY(-50%) rotate(45deg);
}
.search-form .btn-clear:before {
  transform: translateY(-50%) rotate(-45deg);
}
.search-form .btn-search {
  position: absolute;
  top: 50%;
  right: 3em;
  transform: translateY(-50%);
  color: white;
  text-transform: uppercase;
  padding: 0.2em 0.4em;
  border: 1px solid #aaa;
  border-radius: 2px;
}
.search-form .btn-search:hover {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.1);
}
.search-form .btn-search svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
  margin-right: 0.4em;
}
.search-form .btn-search svg path {
  fill: white;
}
.straps-page .search-form-straps .search-input {
  display: flex;
  align-items: center;
}
.straps-page .search-form-straps .input-container {
  padding-right: 1em;
  flex-grow: 1;
}
.straps-page .search-form-straps .btn-search {
  margin: 0;
}
.search-form-straps {
  position: relative;
}
.search-form-straps h3 {
  margin-top: 0;
}
.search-form-straps label {
  color: #575756;
  text-transform: uppercase;
  transition: 0.2s ease;
  cursor: pointer;
}
.search-form-straps .input-container {
  display: flex;
  align-items: center;
}
.search-form-straps .input-inside-container {
  flex-grow: 1;
  position: relative;
  margin-right: 0.75em;
}
.search-form-straps .filter-lugs {
  margin: 0.75em 0;
}
.search-form-straps .filter-lugs label {
  display: block;
}
.search-form-straps .filter-lugs select {
  margin-top: 0.2em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  border-bottom: 1px solid #bbb;
}
.search-form-straps input {
  border: 0;
  border-bottom: 1px solid #bbb;
  background: transparent;
  color: inherit;
  padding: 0.2em 2em 0.2em 0;
  box-sizing: border-box;
  width: 100%;
}
.search-form-straps input:focus {
  outline: none;
  box-shadow: 0;
}
.search-form-straps.focus input {
  border-color: #3363f4;
}
.search-form-straps .btn-info .icon svg {
  width: 1.5em;
  height: 1.5em;
}
.search-form-straps .btn-clear {
  position: absolute;
  top: 50%;
  right: 0.1em;
  transform: translateY(-50%);
  width: 1.5em;
  height: 1.5em;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 2em;
  border: 1px solid white;
  border-radius: 200px;
}
.search-form-straps .btn-clear:after,
.search-form-straps .btn-clear:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0.3em;
  right: 0.3em;
  height: 1px;
  background: #575756;
}
.search-form-straps .btn-clear:after {
  transform: translateY(-50%) rotate(45deg);
}
.search-form-straps .btn-clear:before {
  transform: translateY(-50%) rotate(-45deg);
}
.search-form-straps .btn-search {
  border: 0;
  font-weight: normal;
  letter-spacing: 0.05em;
  padding: 0.4em 1em;
  cursor: pointer;
  transition: 0.3s ease-out;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
  color: white;
  background-color: #575756;
  text-transform: uppercase;
  padding: 0.2em 0.8em;
  border: 1px solid #aaa;
  border-radius: 2px;
  margin-top: 0.75em;
}
.search-form-straps .btn-search:hover {
  text-decoration: none;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
  background-color: #000;
  /*background-color: #f0f0f0;*/
}
.search-form-straps .btn-search:hover {
  text-decoration: none;
}
.search-form-straps .btn-search svg {
  width: 19px;
  height: 19px;
  vertical-align: middle;
  margin-right: 0.4em;
}
.search-form-straps .btn-search svg path {
  fill: white;
}
.search-form-straps .modal .modal-content {
  text-align: center;
}
.search-form-straps .modal .modal-content p {
  text-align: left;
}
.search-form-straps .modal .modal-content .img-ref {
  max-width: 100%;
  width: auto;
  margin: 0 auto;
}
.strap-first-block .search-form-straps .btn-search {
  margin-top: 0.75em;
}
.loader {
  position: relative;
}
.loader .spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);
}
.loader .spinner-container .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner-container {
  padding: 1em;
  text-align: center;
}
.spinner-container.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.spinner {
  display: inline-block;
  position: relative;
  overflow: hidden;
  width: 5em;
  height: 2em;
}
.spinner.white .circle {
  border-color: white;
}
.spinner .circle {
  border: 1px solid #575756;
  width: 0.7em;
  height: 0.7em;
  display: inline-block;
  vertical-align: middle;
  border-radius: 200px;
  animation: spin 2.4s infinite linear;
  position: absolute;
  top: 50%;
}
.spinner .circle:nth-child(1) {
  left: 20%;
  transform: translate(-50%, -50%);
}
.spinner .circle:nth-child(2) {
  left: 50%;
  transform: translate(-50%, -50%);
  animation-delay: 0.8s;
}
.spinner .circle:nth-child(3) {
  left: 80%;
  transform: translate(-50%, -50%);
  animation-delay: 1.6s;
}
.spinner .text {
  position: absolute;
  left: 0;
  bottom: 110%;
}
@keyframes spin {
  0% {
    border-width: 1px;
  }
  50% {
    border-width: 6px;
  }
  100% {
    border-width: 1px;
  }
}
.stock {
  width: 0.7em;
  height: 0.7em;
  border-radius: 100px;
  display: inline-block;
  vertical-align: middle;
}
.stock.stock-green {
  background-color: #8dbb5f;
}
.stock.stock-yellow {
  background-color: #ff0000;
}
.stock.stock-grey {
  background-color: #575756;
}
.watch-full .infos .stock {
  width: 1.25em;
  height: 1.25em;
  margin-right: 0.5em;
  vertical-align: middle;
}
.watch-full .infos .stock-container {
  border-top: 1px solid #bbb;
  padding-top: 0.75em;
  margin: 1em 0;
}
.watch-full .infos .stock-text {
  text-transform: uppercase;
  vertical-align: middle;
  padding-top: 0.2em;
}
.watch-teaser .stock {
  position: absolute;
  top: 1em;
  right: 1em;
}
.watch-full h1 {
  font-weight: 700;
  font-size: 3.25em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
  color: #575756;
}
.watch-full h1 .sub {
  display: block;
  font-weight: 400;
  font-size: 0.75em;
}
.watch-full p.main {
  text-transform: uppercase;
  font-weight: 400;
  text-align: justify;
  font-size: 1.5em;
  hyphens: auto;
}
.watch-full .head {
  padding-top: 2em;
  background: #f4f4f4;
}
.watch-full .head .container {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
}
.watch-full .head .infos {
  padding-left: 5%;
  width: 50%;
  padding-bottom: 2.5em;
}
.watch-full .head .visual {
  width: 45%;
  position: relative;
}
.watch-full .head .visual .img {
  position: relative;
  overflow: hidden;
  padding-top: 4%;
  padding-bottom: 8%;
}
.watch-full .head .visual .img-shadow {
  position: absolute;
  top: 12%;
  left: 0;
  width: 100%;
  z-index: 1;
}
.watch-full .head .visual .img-soldier {
  position: relative;
  display: block;
  width: 100%;
  z-index: 3;
}
.watch-full .head .visual .img-soldier.img-soldier-alt:after {
  content: '';
  display: block;
  padding-top: 100%;
}
.watch-full .head .visual .img-soldier.img-soldier-alt span {
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  text-align: center;
  color: #777;
  transform: translateY(-50%);
}
.watch-full .head .visual .pictos {
  position: absolute;
  z-index: 4;
  top: 48%;
  left: 10%;
  transform: translateY(-50%);
}
.watch-full .head .visual .pictos img {
  width: 36px;
  height: 36px;
  display: block;
  margin: 5px 0;
}
.watch-full .head .designer-word {
  position: absolute;
  top: 0;
  right: -10%;
  background: #333;
  color: white;
  width: 14em;
  height: 14em;
  padding: 3.5em;
  border-radius: 300px;
  text-align: center;
  display: flex;
  flex-flow: column;
  font-size: 0.75em;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
.watch-full .head .designer-word .title {
  text-transform: uppercase;
  color: white;
  display: block;
  font-weight: 700;
  margin: 0;
}
.watch-full .head .designer-word p {
  margin: 0;
}
.watch-full .head .sku {
  font-weight: 700;
  margin-bottom: 0.4em;
  font-size: 1.5em;
}
.watch-full .head .limited {
  margin: 0 0 0.5em;
  font-weight: 600;
  color: #ff0000;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}
.watch-full.watch-T8562051901200 .img-shadow,
.watch-full.family-5570 .img-shadow {
  display: none;
}
.watch-full.watch-T1206141104100 .head .img-soldier {
  margin-left: -5%;
}
.watch-full .tabs {
  margin: 2em 4.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
}
.watch-full .tabs .tabs-menu {
  width: 30%;
}
.watch-full .tabs .tabs-content {
  width: 65%;
}
.watch-full .tabs button {
  color: #575756;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05;
  display: block;
  margin-bottom: 15px;
}
.watch-full .tabs button:hover {
  text-decoration: none;
}
.watch-full .tabs button.active {
  font-weight: 700;
  font-size: 1.5em;
}
.watch-full .tabs .tabs-item {
  font-size: 0.875em;
  border-bottom: 1px solid #575756;
  text-transform: uppercase;
  padding: 0.5em 0;
}
.watch-full .tabs .tabs-item .label {
  margin-right: 0.75em;
  font-weight: 600;
}
.watch-full .tabs .tabs-item:first-child {
  border-top: 1px solid #575756;
}
.watch-full .how-to {
  text-align: justify;
  hyphens: auto;
}
.watch-full .how-to .label {
  text-transform: uppercase;
  margin-right: 0.5em;
  font-weight: 700;
}
.watch-full .how-to p {
  display: inline;
  margin: 0;
}
.watch-full .price-price {
  font-weight: 700;
  color: white;
  padding: 0.4em 0.6em 0.2em;
  background-color: #575756;
  font-size: 2em;
}
.watch-full .price-comment {
  margin-top: 0.25em;
  padding-top: 0.25em;
  text-transform: uppercase;
}
.watch-full .images .watch-teaser {
  overflow: hidden;
  background-image: url(./assets/shadowbase.jpg);
}
.watch-full .images .watch-teaser .content {
  padding-bottom: 130%;
}
.watch-full .images .watch-teaser.img-detail1,
.watch-full .images .watch-teaser.img-detail2,
.watch-full .images .watch-teaser.img-detail3,
.watch-full .images .watch-teaser.img-wrist,
.watch-full .images .watch-teaser.img-strap-view,
.watch-full .images .watch-teaser.img-strap-lugs,
.watch-full .images .watch-teaser.img-superluminova {
  background-image: none;
}
.watch-full .images .watch-teaser.video {
  position: relative;
}
.watch-full .images .watch-teaser.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.watch-full .images img {
  position: absolute;
  width: 90%;
  top: 6%;
  left: 5%;
  height: auto;
}
.watch-full .images .img-zoom,
.watch-full .images .img-wrist,
.watch-full .images .img-superluminova,
.watch-full .images .img-strap-view,
.watch-full .images .img-strap-lugs {
  overflow: hidden;
}
.watch-full .images .img-zoom img,
.watch-full .images .img-wrist img,
.watch-full .images .img-superluminova img,
.watch-full .images .img-strap-view img,
.watch-full .images .img-strap-lugs img {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translateX(-50%);
}
.watch-full .images .img-detail1,
.watch-full .images .img-detail2,
.watch-full .images .img-detail3,
.watch-full .images .img-amb {
  overflow: hidden;
}
.watch-full .images .img-detail1 .content,
.watch-full .images .img-detail2 .content,
.watch-full .images .img-detail3 .content,
.watch-full .images .img-amb .content {
  padding-bottom: 62.35%;
}
.watch-full .images .img-detail1 img,
.watch-full .images .img-detail2 img,
.watch-full .images .img-detail3 img,
.watch-full .images .img-amb img {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-44%);
  width: 100%;
  height: auto;
}
.watch-full .images .img-set img {
  top: 10%;
}
.watch-full .images .img-amb img {
  width: auto;
  height: 100%;
}
.watch-full .links {
  text-align: center;
  margin: 2em 0 2em;
}
.watch-full .links a {
  display: inline-block;
  margin: 0 0.5em 0.5em;
}
.watch-full .links-top {
  margin-top: 1m;
}
.watch-full .links-top a {
  text-transform: uppercase;
  display: inline-block;
  margin: 0 0 0.5em;
  padding: 0.75em 1.5em;
  border: 1px solid #575756;
  background-color: white;
  color: #575756;
  border-radius: 0;
}
.watch-full .links-top a:hover {
  background-color: #f4f4f4;
}
.watch-full .links-top a:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.watch-full .links-top a:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.watch-full .links-top a:not(:first-child) {
  border-left: 0;
}
.watch-full .barcode-container {
  position: absolute;
  bottom: 8%;
  right: 65px;
  z-index: 5;
  transform-origin: right bottom;
  transform: rotate(90deg);
}
.watch-full .barcode {
  margin: 5px 0 0;
  width: 100%;
  max-width: 185px;
  height: auto;
  display: block;
}
.watch-full .product-announcements {
  margin-bottom: 2em;
}
.watch-full .novelty {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  margin-top: -1.5em;
  margin-bottom: 0.5em;
  white-space: nowrap;
}
.watch-full .novelty .novelty-badge {
  font-size: 0.75em;
}
.watch-full .novelty .label {
  text-transform: uppercase;
  font-size: 1em;
  margin-left: 0.7em;
  white-space: normal;
  vertical-align: middle;
}
.watch-full .limited-availability {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;
  margin-top: -1.5em;
  margin-bottom: 0.5em;
  white-space: nowrap;
}
.watch-full .limited-availability .label {
  text-transform: uppercase;
  margin-left: 0.7em;
  white-space: normal;
  vertical-align: middle;
}
.watch-full .breadcrumb {
  padding-top: 0;
  padding-left: 5%;
}
.watch-full .limited-availability + .limited-availability,
.watch-full .novelty + .limited-availability {
  margin-top: 0em;
}
.watch-full-strap .price-price {
  display: inline-block;
  min-width: 8em;
}
.watch-related {
  margin-top: 3em;
  position: relative;
}
.watch-related .nav .bt {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 40px;
}
.watch-related .nav .bt:hover svg path {
  fill: #ff0000;
}
.watch-related .nav .bt.disabled svg path {
  fill: #ccc;
}
.watch-related .nav .bt-next {
  right: 0;
}
.watch-related .nav .bt-prev {
  left: 0;
}
.watch-related .watches {
  overflow: hidden;
}
.watch-related .watches .watches-inside {
  white-space: nowrap;
  position: relative;
  transition: left 0.3s ease;
}
.watch-related.with-nav .watches {
  margin: 0 45px;
}
.watch-related .watch.watch-teaser {
  margin: 0;
  background: none;
  display: inline-block;
  width: 20%;
  white-space: normal;
}
.watch-related .watch.watch-teaser .content {
  padding-bottom: 100%;
}
.watch-related .watch.watch-teaser .img-soldier {
  width: 100%;
  left: 0;
  top: 0;
}
@media screen and (max-width: 1360px) {
  .watch-full .head .visual .img-soldier {
    width: 80%;
  }
  .watch-full .head .visual .img-shadow {
    top: 14%;
    left: 0;
    width: 80%;
  }
  .watch-full .head .visual .pictos {
    left: 7%;
  }
}
@media screen and (max-width: 1210px) {
  .watch-full h1 {
    font-size: 3em;
  }
  .watch-full .head .container {
    padding-left: 25px;
    padding-right: 25px;
  }
  .watch-full .head .infos {
    padding-left: 0;
    width: 48%;
  }
  .watch-full .head .visual {
    width: 48%;
  }
  .watch-full .head .visual .img-shadow {
    top: 22%;
  }
  .watch-full .head .visual .img {
    padding-top: 15%;
  }
  .watch-full .head .visual .pictos {
    top: 53%;
  }
  .watch-full .head .sku {
    font-size: 1.25em;
  }
  .watch-full .tabs {
    margin: 2em 10px;
  }
  .watch-full .novelty {
    margin-top: -1em;
  }
  .watch-full .breadcrumb {
    padding-left: 0;
  }
}
@media screen and (max-width: 990px) {
  .watch-full h1 {
    font-size: 2.5em;
  }
  .watch-full .head .container {
    justify-content: space-between;
  }
  .watch-full .head .infos {
    width: auto;
  }
  .watch-full .head .infos .how-to {
    margin-bottom: 0.75em;
  }
  .watch-full .head .infos .grid {
    display: block;
  }
  .watch-full .head .infos .grid .col-2 {
    width: auto;
  }
  .watch-full .head .visual {
    width: 300px;
    flex-shrink: 0;
    display: flex;
    flex-flow: column-reverse;
  }
  .watch-full .head .visual .img {
    padding-bottom: 25%;
  }
  .watch-full .head .visual .img-soldier {
    width: 120%;
    left: -10%;
  }
  .watch-full .head .visual .img-shadow {
    top: 28%;
    left: 0;
    width: 100%;
  }
  .watch-full .head .visual .designer-word {
    position: static;
    padding: 1.5em;
    margin: 0 auto 1em;
  }
  .watch-full .head .visual .pictos {
    left: 3%;
  }
  .watch-full .head .visual .pictos img {
    width: 24px;
    height: 24px;
  }
  .watch-full .barcode-container {
    position: absolute;
    bottom: 10px;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
  }
  .watch-full .links-top a {
    margin: 0 0.5em 0.5em;
  }
  .watch-full .links-top a:first-child,
  .watch-full .links-top a:last-child {
    border-radius: 8px;
    border-left: 1px solid #575756;
  }
}
@media screen and (max-width: 640px) {
  .watch-full h1 {
    font-size: 2em;
  }
  .watch-full .head {
    padding-top: 5px;
  }
  .watch-full .head .container {
    flex-flow: column-reverse;
  }
  .watch-full .head .visual {
    width: auto;
    display: block;
    position: relative;
  }
  .watch-full .head .visual .img {
    margin: 0 4em;
  }
  .watch-full .head .visual .designer-word {
    position: absolute;
    top: 0;
    right: -30px;
  }
  .watch-full .head .visual .designer-word .inside {
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  .watch-full .head .visual .pictos {
    left: 2%;
  }
  .watch-full .head .visual .pictos img {
    width: 30px;
    height: 30px;
  }
  .watch-full .tabs {
    display: block;
  }
  .watch-full .tabs .tabs-menu {
    width: auto;
    text-align: center;
  }
  .watch-full .tabs .tabs-content {
    width: auto;
    margin-top: 1em;
  }
  .watch-full .tabs button {
    background-color: #575756;
    border: 0;
    text-transform: uppercase;
    color: white;
    font-weight: normal;
    letter-spacing: 0.05em;
    padding: 0.4em 1em;
    border-radius: 2px;
    cursor: pointer;
    transition: 0.3s ease-out;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.06), 0 3px 1px -2px rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    margin: 0 5px 5px 0;
    display: inline-block;
  }
  .watch-full .tabs button:hover {
    text-decoration: none;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
    background-color: #000;
    /*background-color: #f0f0f0;*/
  }
  .watch-full .tabs button.active {
    font-size: 1em;
  }
  .watch-full .novelty,
  .watch-full .limited-availability {
    margin-top: 0;
    margin-bottom: 1em;
    white-space: normal;
  }
  .watch-full .novelty .limited-availability-badge,
  .watch-full .limited-availability .limited-availability-badge,
  .watch-full .novelty .novelty-badge,
  .watch-full .limited-availability .novelty-badge {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-12deg);
  }
  .watch-full .novelty .label,
  .watch-full .limited-availability .label {
    margin-left: 3em;
    font-size: 1em;
  }
  .watch-full .images .watch-teaser.video {
    padding-top: 56.25%;
    max-width: none;
  }
  .watch-full .links-top a {
    display: block;
    box-sizing: border-box;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@media screen and (max-width: 540px) {
  .watch-full h1 {
    font-size: 1.5em;
  }
  .watch-full p.main {
    font-size: 1em;
  }
  .watch-full .head .visual .pictos {
    left: 0;
  }
  .watch-full .head .visual .img {
    margin: 0 5em 0 2em;
  }
}
@media screen and (max-width: 540px) {
  .watch-full .head .container {
    flex-flow: column-reverse;
  }
  .watch-full .head .visual {
    width: auto;
    display: block;
    position: relative;
  }
  .watch-full .head .visual .designer-word {
    position: absolute;
    top: 0;
    right: -30px;
  }
  .watch-full .head .visual .img {
    margin: 3em 6em 0 0em;
  }
}
.filters-nav {
  margin: 1.5em 0 1.5em;
}
.filters-nav .filter-buttons {
  margin-top: 0.5em;
  width: 100%;
}
.filters-nav .filter-buttons .btn {
  margin-top: 0.5em;
}
.breadcrumb + .filters-nav {
  margin-top: 0.5em;
}
.watches-list {
  margin-top: 1.5em;
}
.watches-list .watch-group-title {
  background: #575756 url('./assets/default-wide.jpg') no-repeat 50% 50%;
  margin-bottom: 1.5em;
  background-size: cover;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.watches-list .watch-group-title h3 {
  margin: 0;
}
.watches-list .watch-group-title h2 {
  position: relative;
  z-index: 2;
  margin: 0;
  font-size: 2em;
  padding: 0 1em;
  text-align: center;
}
.watches-list .watch-group-title h2 .sub {
  font-weight: 400;
}
.watches-list .watch-group-title h2 .main {
  display: block;
}
.watches-list .watch-group-title .month {
  font-weight: 600;
  opacity: 0.3;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 22em;
  letter-spacing: -0.07em;
}
.watches-list .spacer {
  margin-bottom: 1.5em;
  background: url('./assets/default-wide.jpg') no-repeat 50% 50%;
  background-position: 50% 50%;
  background-size: cover;
}
.watches-list .spacer.col-2 {
  background-color: #f3f3f3;
}
.watches-list .spacer.img-detail1 {
  background-position: 50% 40%;
}
.watches-list .spacer.img-soldier {
  background-size: 90% auto;
}
.straps-list .watch-teaser:first-child {
  border: 2px solid #575756;
  box-sizing: border-box;
}
.straps-list .watch-teaser:first-child .img-soldier {
  position: absolute;
  width: 66%;
  top: 12%;
  left: 17%;
  height: auto;
}
.watch-teaser {
  display: block;
}
.watch-teaser .pictos {
  position: absolute;
  top: 36%;
  left: 10%;
  transform: translateY(-50%);
}
.watch-teaser .pictos img {
  width: 28px;
  height: 28px;
  display: block;
  margin: 5px 0;
}
.price-legend {
  text-align: center;
  margin: 0.5em 0;
  text-transform: uppercase;
  color: #ff0000;
}
.load-more {
  text-align: center;
  padding: 1em 0;
}
@media screen and (max-width: 640px) {
  .watches-list .watch-group-title {
    max-width: 329px;
    margin-left: auto;
    margin-right: auto;
    padding: 3em 0;
  }
}
.strap-first-block {
  margin-bottom: 1.5em;
  color: #575756;
  background: #f3f3f3;
  padding: 1em;
  box-sizing: border-box;
}
.strap-first-block h3 {
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.25em;
  font-size: 1.5em;
}
.strap-empty-block {
  margin-bottom: 1.5em;
  color: #575756;
  background: #f3f3f3;
  padding: 1em;
  box-sizing: border-box;
}
.watch-teaser {
  position: relative;
  margin-bottom: 1.5em;
  color: #575756;
  background: #f3f3f3;
}
.watch-teaser.with-image {
  background: url('./assets/shadowlight2.jpg') no-repeat 50% 50%;
  background-size: cover;
}
.watch-teaser > a {
  display: block;
  color: #575756;
}
.watch-teaser .content {
  position: relative;
  padding-bottom: 120.06%;
}
.watch-teaser .content .content-inside {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.watch-teaser .img-soldier {
  position: absolute;
  width: 76%;
  top: 4%;
  left: 12%;
  height: auto;
}
.watch-teaser .img-soldier.img-soldier-alt:after {
  content: '';
  display: block;
  padding-top: 100%;
}
.watch-teaser .img-soldier.img-soldier-alt span {
  position: absolute;
  top: 50%;
  left: 15px;
  right: 15px;
  text-align: center;
  color: #777;
  font-size: 0.875em;
  transform: translateY(-50%);
}
.watch-teaser .infos {
  text-align: center;
  position: absolute;
  bottom: 4%;
  left: 5%;
  right: 5%;
  bottom: 5%;
}
.watch-teaser .infos h3 {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 0.15em;
  font-size: 1em;
}
.watch-teaser .infos .sku {
  text-transform: uppercase;
}
.watch-teaser .infos .price {
  padding-top: 0.5em;
  font-size: 0.875em;
  color: #ff0000;
  text-transform: uppercase;
}
.watch-teaser .novelty-badge {
  position: absolute;
  top: 10%;
  right: 4%;
  font-size: 1em;
}
.watch-teaser .diameter {
  position: absolute;
  bottom: 35%;
  right: 10%;
}
.watch-teaser .teaser-title {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.5em 0.75em;
  text-align: center;
  background-color: #575756;
  color: white;
  text-transform: uppercase;
}
.diameter {
  text-align: center;
}
.diameter img {
  width: 24px;
  height: 24px;
}
.diameter span {
  display: block;
  text-align: center;
  font-weight: 700;
}
.novelty-badge {
  font-size: 0.875em;
  background-color: #ff0000;
  background: radial-gradient(circle at 75% 25%, #ff0000 0%, #c40000 100%);
  color: white;
  width: 3.3em;
  height: 3.3em;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.limited-availability-badge {
  font-size: 1.5em;
  background-color: #ff8800;
  background: radial-gradient(circle at 75% 25%, #ff8800 0%, #bd6500 100%);
  color: white;
  width: 1.65em;
  height: 1.65em;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
.pictos .picto {
  position: relative;
  display: block;
}
.pictos .picto .tooltip {
  display: none;
  position: absolute;
  top: 50%;
  left: 100%;
  margin-left: 0.5em;
  transform: translateY(-50%);
}
.pictos .picto .tooltip span {
  display: inline-block;
  font-size: 0.875em;
  padding: 4px 6px;
  background: white;
  border: 1px solid #bbb;
  border-radius: 4px;
  white-space: nowrap;
  color: #575756;
}
.pictos .picto:hover .tooltip,
.pictos .picto.active .tooltip {
  display: block;
}
.watch-teaser .pictos .picto .tooltip {
  width: 15em;
}
.watch-teaser .pictos .picto .tooltip span {
  white-space: normal;
}
@media screen and (max-width: 1360px) {
  .watch-teaser .infos {
    font-size: 0.9em;
    bottom: 2%;
  }
  .watch-teaser .pictos .picto .tooltip {
    width: 13em;
  }
}
@media screen and (max-width: 1210px) {
  .watch-teaser .infos {
    font-size: 0.8em;
  }
  .watch-teaser .pictos .picto .tooltip {
    width: 11em;
  }
}
@media screen and (max-width: 990px) {
  .watch-teaser .infos {
    font-size: 1em;
  }
  .watch-teaser .pictos .picto .tooltip {
    width: 15em;
  }
}
@media screen and (max-width: 640px) {
  .watch-teaser {
    max-width: 329px;
    margin-left: auto;
    margin-right: auto;
  }
  .watch-teaser .infos {
    font-size: 1em;
  }
}
@media screen and (max-width: 340px) {
  .watch-teaser .pictos .picto .tooltip {
    width: 13em;
  }
}
.contents-page {
  color: #706f6f;
}
.contents-page h1 {
  font-size: 4em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  line-height: 1.1;
  margin: 1.5em 0 0.5em 0;
}
.contents-page h2.big {
  font-size: 6em;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1;
}
.contents-page h2.main {
  font-size: 3em;
  margin: 0em 0 0.6em;
  text-align: center;
}
.contents-page h3 {
  font-size: 1.5em;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  margin: 0 0 0.5em;
}
.contents-page h4 {
  font-size: 1.25em;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0.75em 0;
}
.contents-page .trailer-1 {
  margin-bottom: 1em;
}
.contents-page .leader-1 {
  margin-top: 1em;
}
.contents-page .leader-2 {
  margin-top: 2em;
}
.contents-page .leader-in-1 {
  padding-top: 1em;
}
.contents-page .leader-in-2 {
  padding-top: 2em;
}
.contents-page .lead {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 0.05em;
}
.contents-page img {
  max-width: 100%;
  height: auto;
}
.contents-page img.block {
  display: block;
}
.contents-page img.optional {
  max-width: none;
  width: auto;
  height: 70px;
}
.contents-page .text-spaced {
  padding: 5em;
}
.contents-page .col-img {
  display: flex;
  align-items: center;
}
.contents-page .bg-dark {
  padding: 0 0 0 2em;
  border-left: 1px solid #ff0000;
  box-sizing: border-box;
}
.contents-page .bg-dark h2 {
  color: #ff0000;
}
.contents-page .bg-dark > :first-child {
  margin-top: 0;
}
.contents-page .relative {
  position: relative;
}
.contents-page .upper {
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.contents-page .inlines {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  color: #575756;
}
.contents-page .inlines > * {
  margin-right: 0.75em;
}
.contents-page .inlines img {
  display: block;
}
.contents-page .inlines .pt {
  padding-top: 0.3em;
}
.contents-page .inlines .pb {
  padding-bottom: 0.3em;
}
.contents-page .recommended {
  position: absolute;
  width: 7em;
  height: 7em;
  border-radius: 200px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: #ff0000;
  color: #ffcccc;
  top: -1.5em;
  right: 0.5em;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.contents-page .recommended.interval {
  width: 10em;
  height: 10em;
}
.contents-page .recommended span.count {
  color: white;
  font-size: 1.4em;
  font-weight: 700;
}
.contents-page .odd .recommended {
  right: auto;
  left: 0;
}
.contents-page .image-text {
  position: relative;
  text-align: center;
}
.contents-page .image-text .text {
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 1.5em;
  text-align: center;
}
.contents-page .image-text .text h3 {
  font-size: 1.25em;
  margin: 0;
}
.contents-page .image-text .text p {
  margin: 0;
}
.contents-page .image-text .text.big {
  font-size: 1.25em;
  bottom: 2em;
}
.contents-page .image-text .text.center {
  bottom: auto;
  top: 50%;
  transform: translateY(-50%);
}
.contents-page .image-text .white {
  color: white;
}
.contents-page .image-button {
  position: relative;
  overflow: hidden;
}
.contents-page .image-button svg {
  transform-origin: 50% 50%;
  position: absolute;
  top: 10px;
  left: 10px;
}
.contents-page .image-button svg path {
  fill: black;
}
.contents-page .image-button .text {
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  height: 100%;
  padding: 3.5em 1.5em 1.5em 1.5em;
  box-sizing: border-box;
  color: white;
  background-color: rgba(33, 33, 33, 0.8);
  transition: bottom 0.3s ease;
}
.contents-page .image-button .text h4 {
  margin: 0.4em 0 0.1em;
}
.contents-page .image-button .text p {
  margin: 0.2em 0;
}
.contents-page .image-button .text strong {
  color: #ff0000;
}
.contents-page .image-button.active svg {
  transform: rotate(45deg);
}
.contents-page .image-button.active svg path {
  fill: white;
}
.contents-page .image-button.active .text {
  bottom: 0;
}
.contents-page .include-list {
  margin: 0.25em 0;
  padding-left: 0.9em;
}
.contents-page .include-list.cols {
  columns: 2;
}
.contents-page ul.rules-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.contents-page ul.rules-list li {
  margin: 1em 0;
  text-transform: uppercase;
  padding-left: 0;
}
.contents-page .note {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: stretch;
  margin: 0.5em 0;
}
.contents-page .note span:last-child {
  background-color: #ff0000;
  padding: 0.75em 1.25em;
  color: white;
}
.contents-page .note .icon {
  background: black;
  padding: 0.5em;
}
.contents-page .note .icon svg {
  width: 1.5em;
  height: 1.5em;
}
.contents-page .content {
  margin: 1.5em 0;
}
.contents-page .content.wide {
  padding: 1.5em 0;
}
.contents-page .content.dark {
  background-color: #575756;
  color: white;
}
.contents-page .content-head {
  position: relative;
  overflow: hidden;
  margin-top: 0;
}
.contents-page .content-head .stacked-left {
  position: absolute;
  left: 0;
  width: 40%;
  z-index: 1;
}
.contents-page .content-head .band {
  position: absolute;
  left: 0;
  right: 0;
  height: 15em;
  bottom: 6em;
  background-color: #ff0000;
  mix-blend-mode: multiply;
  z-index: 2;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
}
.contents-page .content-head .above {
  z-index: 5;
}
.contents-page .content-menu.fixed .content-menu-container {
  position: fixed;
  left: 0;
  right: 0;
  background: white;
  z-index: 2;
}
.contents-page .content-menu.fixed .main-menu {
  display: none;
}
.contents-page .content-menu.fixed .content-menu-menu .bt {
  display: block;
}
.contents-page .content-menu.fixed.menu-opened .main-menu {
  display: block;
  position: absolute;
  padding-bottom: 1em;
  box-shadow: 0px 5px 3px rgba(0, 0, 0, 0.1);
  top: 100%;
  left: 0;
  right: 0;
}
.contents-page .content-menu.fixed.menu-opened .content-menu-menu .bt:after {
  transform: translateY(-40%) rotate(45deg);
}
.contents-page .content-menu .content-menu-menu {
  position: relative;
}
.contents-page .content-menu .content-menu-menu .bt {
  display: none;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding-right: 2em;
  text-align: center;
}
.contents-page .content-menu .content-menu-menu .bt:after {
  content: '';
  display: block;
  position: absolute;
  width: 0.6em;
  height: 0.6em;
  border-top: 1px solid #575756;
  border-left: 1px solid #575756;
  transform: translateY(-50%) rotate(-135deg);
  transform-origin: 50% 50%;
  transition: transform 0.3s ease;
  top: 46%;
  right: 0.5em;
  z-index: 2;
}
.contents-page .content-menu .content-menu-menu .bt a {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}
.contents-page .content-menu ul {
  white-space: nowrap;
  padding: 0;
  margin: 0;
  list-style: none;
}
.contents-page .content-menu ul li {
  display: inline-block;
}
.contents-page .content-menu ul li a {
  text-transform: uppercase;
  display: block;
  padding: 0.3em 0.5em;
  color: #575756;
}
.contents-page .content-menu ul li a.active {
  color: #ff0000;
}
.contents-page .content-menu ul.main-menu {
  background-color: white;
  column-count: 3;
}
.contents-page .content-menu ul.main-menu li {
  display: block;
}
.contents-page .btn-top {
  width: 2.4em;
  height: 2.4em;
  position: fixed;
  bottom: 1.1em;
  right: 3em;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 3em;
  border: 1px solid;
  color: white;
  background-color: #575756;
  z-index: 30;
}
.contents-page .btn-top:hover {
  background-color: #71716f;
}
.contents-page .btn-top:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-left: 1px solid;
  border-top: 1px solid;
  transform: translate(-50%, -20%) rotate(45deg);
}
.contents-page .content-display .display {
  padding: 2em 0 0;
}
.contents-page .content-display .display > :last-child {
  padding-bottom: 2em;
  border-bottom: 1px solid #bbb;
}
.contents-page .content-imagelinks h2.main {
  text-align: center;
  font-size: 3em;
}
.contents-page .content-imagelinks .bt-show-more {
  display: block;
  text-align: center;
  color: #706f6f;
  width: 100%;
}
.contents-page .content-imagelinks .bt-show-more h2 {
  margin-bottom: 0.5em;
}
.contents-page .content-imagelinks .bt-show-more:hover {
  text-decoration: none;
  color: #ff0000;
}
.contents-page .content-rules h2.main {
  font-size: 2.5em;
  margin-top: 0;
  line-height: 1;
}
.contents-page .content-rules .visual {
  position: relative;
}
.contents-page .content-rules .visual img {
  display: block;
}
.contents-page .content-rules .visual svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.contents-page .content-rules .square {
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  border: 2px solid;
  vertical-align: middle;
  margin-right: 0.6em;
}
.contents-page .content-rules ul {
  list-style: none;
  margin: 1em 0;
  padding: 0;
}
.contents-page .content-rules li {
  position: relative;
  margin: 0.75em 0;
  padding-left: 1.5em;
}
.contents-page .content-rules li .square {
  position: absolute;
  top: 0;
  left: 0;
}
.contents-page .content-rules li button {
  text-transform: uppercase;
  color: #706f6f;
}
.contents-page .content-rules li.active button {
  font-weight: 600;
  color: #ff0000;
}
.contents-page .content-rules g.active rect,
.contents-page .content-rules g.active polygon {
  stroke-width: 5px;
  filter: drop-shadow(3px 5px 2px rgb(0 0 0));
}
@media screen and (max-width: 1360px) {
  .contents-page h2.big {
    font-size: 4em;
  }
  .contents-page .text-spaced {
    padding: 3.5em;
  }
  .contents-page .image-text .text {
    font-size: 0.8em;
  }
  .contents-page .image-text .text.big {
    font-size: 1.2em;
  }
}
@media screen and (max-width: 990px) {
  .contents-page h1 {
    font-size: 3em;
  }
  .contents-page h2.big {
    font-size: 3em;
  }
  .contents-page .text-spaced {
    padding: 2em;
  }
  .contents-page .content-menu ul.main-menu {
    columns: 2;
  }
}
@media screen and (max-width: 640px) {
  .contents-page h1 {
    font-size: 2.4em;
  }
  .contents-page h2.big {
    font-size: 2.4em;
  }
  .contents-page .text-spaced {
    padding: 1em 0;
  }
  .contents-page .content-head .stacked-left {
    position: static;
    width: auto;
  }
  .contents-page .content-head .band {
    display: none;
  }
  .contents-page .btn-top {
    bottom: 1em;
    right: 1em;
  }
  .contents-page .col-img {
    display: block;
  }
  .contents-page .recommended,
  .contents-page .recommended.interval {
    position: static;
    margin: 0 auto;
    border-radius: 200px;
    height: auto;
    padding: 0.7em 1.4em;
  }
  .contents-page .display.even .grid.stretch {
    display: flex;
    flex-flow: column-reverse nowrap;
  }
  .contents-page .content-menu ul.main-menu {
    columns: 1;
  }
  .content-rules.even .container > .grid {
    display: flex;
    flex-flow: column-reverse wrap;
  }
}
.contents-page .breadcrumb {
  padding-bottom: 0.4em;
}
.merchandising-displays .content-2 .container {
  background: url(./assets/displayband3.jpg) no-repeat 50% 100%;
  background-size: 100% auto;
  padding-bottom: 3em;
}
.content-head.content-head-basic img {
  display: block;
}
.content-head.content-head-basic .grid {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
.content-head.content-head-basic .band {
  bottom: 0;
}
.content-head.content-head-basic h1 {
  color: white;
}
.merchandising-guidelines .content-grules h2.main {
  text-align: center;
  padding-top: 1.5em;
  margin-bottom: 0;
}
.merchandising-guidelines .content-grules img {
  display: block;
}
.merchandising-guidelines .content-grules .buttons {
  position: absolute;
  bottom: 0.8em;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.merchandising-guidelines .lessismore {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 7em;
  background-color: #f4f4f4;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 2.5em;
  color: #bcbcbc;
}
.merchandising-guidelines .number {
  font-size: 1.5em;
  font-weight: 700;
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  box-sizing: border-box;
  padding-top: 0.15em;
  border-radius: 200px;
  margin-right: 0.4em;
  background-color: #f4f4f4;
  color: #706f6f;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.merchandising-guidelines .number:hover {
  box-shadow: 0px 4px 3px rgba(0, 0, 0, 0.3), 1px 1px 2px rgba(255, 255, 255, 0.9) inset;
  text-decoration: none;
}
.merchandising-guidelines .number.active {
  color: white;
  background-color: #ff0000;
}
.merchandising-guidelines .rules-main-img {
  position: relative;
}
.merchandising-guidelines .rules-main-img img {
  display: block;
}
.merchandising-guidelines .rules-main-img svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.merchandising-guidelines .content-rules .bg-dark {
  padding: 1.5em 3em;
}
.setup-informations .p-m-top {
  margin-top: 5em;
}
@media screen and (max-width: 990px) {
  .content-head.content-head-basic .grid {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .content-head.content-head-basic .band {
    height: 12em;
  }
  .content-head.content-head-basic h1 {
    font-size: 2.8em;
  }
  .setup-informations .p-m-top {
    margin-top: 0;
  }
  .setup-informations .col-1-3,
  .setup-informations .col-2-3 {
    text-align: center;
  }
}
@media screen and (max-width: 640px) {
  .content-head.content-head-basic .grid {
    left: 20px;
    right: 20px;
  }
  .content-head.content-head-basic .band {
    display: block;
    height: 6em;
  }
  .content-head.content-head-basic h1 {
    font-size: 2em;
  }
  .contents-page h2.main {
    font-size: 2em;
  }
  .merchandising-guidelines .content-grules .buttons {
    position: static;
    transform: none;
    white-space: nowrap;
    text-align: center;
    padding-top: 10px;
  }
  .merchandising-guidelines .content-grules .buttons .number {
    font-size: 1.2em;
  }
}
