@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;400;700;900&family=Noto+Sans+TC:wght@100;400;700;900&family=Roboto:wght@100;400;700;900&display=swap');
@font-face {
  font-family: "TissotSans";
  src: url('./fonts/TissotSans-Regular.woff2') format('woff2'),
       url('./fonts/TissotSans-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: "TissotSans";
  src: url('./fonts/TissotSans-Medium.woff2') format('woff2'),
       url('./fonts/TissotSans-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: "TissotSans";
  src: url('./fonts/TissotSans-Semibold.woff2') format('woff2'),
       url('./fonts/TissotSans-Semibold.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: "TissotSans";
  src: url('./fonts/TissotSans-Bold.woff2') format('woff2'),
       url('./fonts/TissotSans-Bold.woff') format('woff');
  font-weight: 700;
}
